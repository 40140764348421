/**
 * The Default Admin Menu
 */
export const ModulesList = [{
  label: 'Administration',
  children: [{
    label: 'Nutzerkonten',
    icon: 'supervisor_account',
    path: 'businessuser'
  }]
},
{
  label: 'Mandant',
  children: [{
    label: 'Basis',
    icon: 'description',
    path: 'mandant/base'
  }, {
    label: 'Parameter',
    icon: 'list',
    path: 'mandant/params'
  }, {
    label: 'Rechtliches',
    icon: 'account_balance',
    path: 'mandant/legal'
  }, {
    label: 'Services',
    icon: 'star',
    path: 'mandant/services'
  },
  ]
},
{
  label: 'Im- und Export',
  children: [{
    label: 'Datei-Import',
    icon: 'cloud_upload',
    path: 'import'
  }, {
    label: 'Datei-Export',
    icon: 'cloud_download',
    path: 'export'
  }, {
    label: 'Batch-Status',
    icon: 'build',
    path: 'processed'
  },{
    label: 'Abfallkalender',
    icon: 'trash',
    path: 'garbage'
  }]
}];