/**
 * The Default SB Menu (Only Reporting)
 */
export const SBModulesListDUS = [{
  label: 'Reports',
  children: [{
    label: 'Aktionen Report',
    icon: 'data_usage',
    path: 'report',
    disabled: false
  }]
}
];