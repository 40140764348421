import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PropertyService } from 'src/app/services/property.service';
/**
 * The Logo of the Mandant
 */
@Component({
  selector: 'app-mandant-logo',
  templateUrl: './mandant-logo.component.html',
  styleUrls: ['./mandant-logo.component.scss'],
})
export class MandantLogoComponent implements OnInit {
  /**
   * The logo as a Subject 
   */
  logo$: Subject<string> = new Subject()
  /**
   * constructor
   * @param propertyService 
   */
  constructor(
    private propertyService: PropertyService,
  ) { }
  /**
   * Set the Logo via the propertyService
   */
  ngOnInit() {
    this.logo$ = this.propertyService.logo$
  }
}
