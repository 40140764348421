import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
/**
 * The Service to show the Sidenav
 */
@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  /**
   * The sidenav as an Object
   */
  private sidenav: MatSidenav;
  /**
   * The constructor for the SidenavService
   */
  constructor() { }
  /**
   * 
   * @param sidenav 
   */
  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }
  /**
   * Open the sidenav
   * @returns 
   */
  public open() {
    return this.sidenav.open();
  }
  /**
   * Close the sidenav
   * @returns 
   */
  public close() {
    return this.sidenav.close();
  }
  /**
   * Toggle the Sidenav
   */
  public toggle(): void {
    this.sidenav.toggle();
  }

}
