/**
 * The Default Partner Menu
 */

export const PartnerModulesList = [
  {
    label: 'Aktionen',
    children: [{
      label: 'Übersicht',
      icon: 'dashboard',
      path: 'event',
    }, {
      label: 'Freigaben',
      icon: 'new_releases',
      path: 'release'
    }]
  },
  {
    label: 'Gutscheine',
    children: [{
      label: 'Übersicht',
      icon: 'dashboard',
      path: 'reservation',
      disabled: false
    }]
  },
  {
    label: 'Reports',
    children: [{
      label: 'Aktionen Report',
      icon: 'data_usage',
      path: 'report',
      disabled: false
    }]
  }
];