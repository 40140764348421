<footer class="footer">
    <mat-toolbar color="accent" class="footerContent">
        <span class="footer-copy">&copy; GATES {{year}}</span>
        <span class="footer-copy" (click)="moveToLegalPage('about')">
            Impressum
        </span>
        <span class="footer-copy"  (click)="moveToLegalPage('privacy')">
            Datenschutz
        </span>
        <span class="footer-copy"  (click)="moveToLegalPage('data')">
            Teilnahme
        </span>
        <span class="footer-copy"  (click)="moveToLegalPage('contact')">
            Kontakt
        </span>
    </mat-toolbar> 
</footer> 