<div class="sticky-header">
    <mat-toolbar color="accent">
      @if (this.mode == 'over') {
        <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      }

      <a>{{title}}</a>
        <span fxFlex></span>
        <a routerLink="" class="logoutHeader" mat-button (click)="logout()">
          Abmelden
        </a>
      </mat-toolbar>
</div>