import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
/**
 * The Footer of the Application
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**
   * The Current Year
   */
  year: String;
  /**
   * constructor 
   * Set the Year
   */
  constructor(
    private router:Router,
    private authenticationService:AuthenticationService) {
    this.year = new Date().getFullYear().toString()
  }
/**
 * ngOnInit
 */
  ngOnInit() {
  }

  moveToLegalPage(type:String){


    this.router.navigate([    this.authenticationService.isSB() == true ? 'caseworker' :'admin','legal', type]);
  }
}
